import logo from './kernzen-new.png';
import './App.css';

const App = () => {
  return <>
    <div className="h-screen w-screen overflow-hidden bg-[#d50c2c] mx-auto flex items-center justify-center select-none">
      <img src={logo} alt={"KERNZEN Logo"} className="select-none" />
    </div>
  </>
}

export default App;
